import { h, Component } from 'preact';
import { connect } from 'react-redux';
import SimpleSearch from './SimpleSearch';
import EditingBtns from './EditingBtns';
import { getUsers } from '../store/actioncreators';
import linkState from 'linkstate';
import axios from 'axios';
import Popup from './Popup';
import AddNewUser from './AddNewUser'

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: '',
			activeUser: {}
		};
		this.searchTermFilterBy = this.searchTermFilterBy.bind(this);
		this.user = this.user.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.toggle = this.toggle.bind(this);

		if (this.props.users.length === 0) {
			this.props.getUsers();
		}
	}
	searchTermFilterBy({ username, _id, role }) {
		return `${username} ${_id} ${role}`;
	}
	user(user) {
		let isActive = false;
		const toggle = (user) => {
			this.setState({
				activeUser: user,
				isActive: true
			});
		};
		return <div className="invoice__row row customer" key={user._id}>
			<div className="left">
				<b>{user.username}</b>
			</div>
			<div className="left" style={{ marginLeft: 20 }}>
				{user.role}
			</div>
			<EditingBtns data={user} handleEdit={toggle} handleDelete={this.handleDelete} />
		</div>
	}
	handleDelete(user) {
		const confirmed = confirm("Are you sure you want to delete " + user.username);
		if (confirmed) {
			axios({
				url: `/api/user/${user._id}`,
				method: 'delete',
				headers: {
					Authorization: `Bearer ${this.props.auth.user.token}`
				}
			})
				.then(() => {
					alert('Successfully deleted ' + user._id);
					location.reload();
				})
				.catch((err) => {
					alert("Something went wrong - " + err.message);
				})
		}
	}
	toggle() {
		this.setState({
			isActive: !this.state.isActive
		})
	}
	render() {
		return <div>
			<div className="search">
				<input type="text" onChange={linkState(this, 'searchTerm')} value={this.state.searchTerm} placeholder="Search" />
			</div>
			<h1>Users</h1>
			<div className="rows">
				<SimpleSearch
					items={this.props.users}
					filterBy={this.searchTermFilterBy}
					searchTerm={this.state.searchTerm}
					component={this.user}
				/>
			</div>
			<Popup open={this.state.isActive} onClose={this.toggle}>
				<AddNewUser {...this.state.activeUser} method="put" auth={this.props.auth} userId={this.state.activeUser._id} />
			</Popup>
		</div>
	}
}


const mapStateToProps = state => ({
	users: state.users.all
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getUsers() {
			dispatch(getUsers(ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);