import { h, render } from 'preact';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOutUser } from '../store/actioncreators';

const Nav = ({ username, logOut, user, className }) =>
	<nav className={className}>
		<div className="welcome">
			Welcome, <NavLink to="/myaccount">{username}</NavLink>
		</div>
		<ul>
			<li>
				<NavLink className="nav__link" to="/" exact>Home</NavLink>
			</li>
			<li>
				<NavLink className="nav__link" to="/add/invoice">Add New Invoice</NavLink>
			</li>
			<li>
				<NavLink className="nav__link" to="/add/proposal">Add New Proposal</NavLink>
			</li>
			<li>
				<NavLink className="nav__link" to="/customers">Customers</NavLink>
			</li>
			<li>
				<NavLink className="nav__link" to="/add/customer">Add New Customer</NavLink>
			</li>
			{user.role === "admin" ? (
			<li>
				<NavLink className="nav__link" to="/users">Users</NavLink>
			</li>						
			) : null}
			{user.role === "admin" ? (
			<li>
				<NavLink className="nav__link" to="/add/user">Add New User</NavLink>
			</li>			
			) : null}
			<li>
				<NavLink className="nav__link" to="/myaccount">My Account</NavLink>
			</li>			
			<li>
				<button className="log-out-btn" onClick={logOut}>Log out</button>
			</li>
		</ul>
	</nav>;

const mapDispatchToProps = dispatch => ({
	logOut() {
		dispatch(logOutUser());
	}
});

export default connect(null, mapDispatchToProps)(Nav);
