import { h, Component } from 'preact';
import linkState from 'linkstate';

const initialState = {
	price: "0",
	qty: "",
	desc: ""
};

class InvoiceInput extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, initialState, this.props.initialState);
		this.handleClick = this.handleClick.bind(this);
		this.handlePrice = this.handlePrice.bind(this);
		this.handleQty = this.handleQty.bind(this);
	}
	handleClick() {
		this.props.onClick(this.state);
	}
	handleQty(e) {
		this.setState({ qty: e.target.value });
		this.props.onPrice();
	}
	handlePrice(e) {
		this.setState({ price: e.target.value });
		this.props.onPrice();
	}
	render() {
		const isRequired = this.props.key === 0 ? {'required' : 'required'} : {};
		return <div className="row">
        <div className="col-md-7">
          <div className="input__row">
            <label htmlFor="description">Description</label>
            <textarea rows="5" cols="20" name="desc[]" placeholder="Description" onInput={linkState(this, "desc")} value={this.state.desc} {...isRequired}>
              Description
            </textarea>
          </div>
        </div>
        <div className="col-md-1">
          <div className="input__row">
            <label htmlFor="qty">Qty</label>
            <input type="text" name="qty[]" placeholder="Qty" onInput={this.handleQty} value={this.state.qty} />
          </div>
        </div>
        <div className="col-md-3">
          <div className="input__row">
            <label htmlFor="price">Price</label>
            <input type="text" name="price[]" placeholder="Price" onInput={this.handlePrice} value={this.state.price} />
          </div>
        </div>
        <div className="col-md-1">
          <div className="input__row">
            {this.props.showBtn ? <a className="btn invoice__add-btn" onClick={this.handleClick}>
                  +
                </a> : null}
          </div>
        </div>
      </div>;
	}
}

InvoiceInput.defaultProps = {
	initialState,
	showBtn: true
};

export default InvoiceInput;
