import { h, Component } from 'preact';
import { connect } from 'react-redux';
import linkState from 'linkstate';
import { fetchCustomers, addCustomer, editCustomer, deleteCustomer } from '../store/actioncreators';
import Customer from './Customer';
import CustomerForm from './CustomerForm';
import Popup from './Popup';
import SimpleSearch from './SimpleSearch';

class Customers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAddOverlayActive: false,
			isEditOverlayActive: false,
			isDeleteOverlayActive: false,
			activeCustomer: {},
			successMsg: "",
			isReverseFilter: false,
			lastActionDelete: false,
			searchTerm: ''
		};
		if (this.props.customers.length === 0) {
			this.props.getCustomers();
		}
		this.handleEdit = this.handleEdit.bind(this);
		this.addNewCustomer = this.addNewCustomer.bind(this);
		this.handleEditSubmit = this.handleEditSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.reset = this.reset.bind(this);
		this.reverseFilter = this.reverseFilter.bind(this);
		this.searchTermFilterBy = this.searchTermFilterBy.bind(this);
	}
	showMessage(message, additional = {}) {

		this.setState(Object.assign({
			message
		}, additional));

		setTimeout(() => {
			this.setState({
				message: ""
			});
		}, 5000);
	}
	componentWillReceiveProps(props) {
		const upcomingLength = props.customers.length;
		const currentLength = this.props.customers.length;

		if (currentLength > upcomingLength) {
			alert("Successfully Deleted Customer");
			location.reload();
		}
		else if (props.editCustomerSuccessId !== "") {
			this.reset(() => {
				setTimeout(() => {
					alert("Successfully Edited Customer");
					window.location = `/customer/${props.editCustomerSuccessId}`;
				}, 0);
			});
		}
		else if (props.deleteCustomerErrorMsg) {
			this.showMessage(props.deleteCustomerErrorMsg, {
				isAddOverlayActive: false,
				isDeleteOverlayActive: false,
				activeCustomer: {},
				lastActionDelete: false
			});
		}
	}
	handleEdit(customer) {
		this.setState({
			isDeleteOverlayActive: false,
			isAddOverlayActive: false,
			isEditOverlayActive: true,
			activeCustomer: customer
		});
	}
	handleEditSubmit(customerData) {
		this.props.editCustomer(customerData);
	}
	handleDelete(customer) {
		const prompt = window.confirm("Are you sure you want to delete?");
		if (prompt) {
			this.props.deleteCustomer(customer);
		}
	}
	addNewCustomer() {
		this.setState({
			isAddOverlayActive: true,
			isEditOverlayActive: false
		});
	}
	reset(cb) {
		this.setState(() => ({
			isAddOverlayActive: false,
			isEditOverlayActive: false,
			isDeleteOverlayActive: false,
			activeCustomer: {}
		}), cb);
	}
	reverseFilter() {
		this.setState({
			isReverseFilter: !this.state.isReverseFilter
		});
	}
	searchTermFilterBy(customer) {
		return `${customer._id} ${customer.name.first} ${customer.name.last} ${customer.address} ${customer.email} ${customer.phone}`;
	}
	render() {
		return (
			<div>
				<div className="search">
					<input type="text" onChange={linkState(this, 'searchTerm')} value={this.state.searchTerm} placeholder="Search" />
				</div>
				<h1>Customers</h1>
				<Popup open={this.state.message} className="col-md-4" onClose={this.reset}>
					{this.state.message}
				</Popup>

				<div className="filter row">
					<div className="col-md-3">
						{/* <button onClick={this.reverseFilter}>Reverse Name</button> */}
					</div>
					<div className="col-md-3"></div>
					<div className="col-md-3"></div>
					<div className="col-md-3"></div>
				</div>

				<SimpleSearch
					className={`rows ${this.state.isReverseFilter ? 'rows--reverse' : ''}`}
					items={this.props.customers}
					filterBy={this.searchTermFilterBy}
					searchTerm={this.state.searchTerm}
					component={(customer) => <Customer
						key={customer._id}
						customer={customer}
						displayChooseOption={false}
						isEditable={this.props.isEditable}
						handleEdit={this.handleEdit}
						handleDelete={this.handleDelete}
					/>}
				/>

			<Popup open={this.state.isEditOverlayActive} className="col-md-4" onClose={this.reset}>
				<CustomerForm customer={this.state.activeCustomer} handleSubmit={this.handleEditSubmit} />
			</Popup>

			<Popup open={this.state.isAddOverlayActive} className="col-md-4" onClose={this.reset}>
				<CustomerForm customer={{}} handleSubmit={this.props.addNewCustomer} />
			</Popup>
			</div >
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: ownProps.auth.user,
		customers: state.customers.latest,
		editCustomerSuccessId: state.customers.editCustomerSuccessId,
		addCustomerErrorMsg: state.customers.addCustomerErrorMsg,
		deleteCustomerErrorMsg: state.errors.errorMsg
	}
}


const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addNewCustomer(customerData) {
			dispatch(addCustomer(customerData, ownProps.auth.user.token));
		},
		editCustomer(customerData) {
			dispatch(editCustomer(customerData, ownProps.auth.user.token));
		},
		deleteCustomer(customerData) {
			dispatch(deleteCustomer(customerData, ownProps.auth.user.token));
		},
		getCustomers() {
			dispatch(fetchCustomers(ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
