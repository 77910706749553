import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { addInvoice } from '../store/actioncreators';
import InvoiceForm from './InvoiceForm';

class AddInvoice extends Component {
	render() {
		return <div>
			<h1>Add new {this.props.type}</h1>
			<InvoiceForm onSubmit={this.props.addInvoice} auth={this.props.auth} type={this.props.type} />
		</div>
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addInvoice(invoiceData) {
			dispatch(addInvoice(invoiceData, ownProps.auth.user.token));
		}
	};
};

AddInvoice.defaultProps = {
	type: "invoice"
}

export default connect(null, mapDispatchToProps)(AddInvoice);
