import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { fetchLatestInvoices, getInvoiceCount } from '../store/actioncreators';
import InvoiceRow from './InvoiceRow';
import SimpleSearch from './SimpleSearch';
import linkState from 'linkstate';
import format from 'date-fns/format';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		const dontShow = this.props.invoices.length >= 0 && this.props.invoices.length >= this.props.count;
		this.state = {
			currentPage: 1,
			searchTerm: '',
			showNextBtn: dontShow ? false : true
		};
		// If there are no invoices request to get some
		if (this.props.invoices.length === 0) {
			this.props.getLatestInvoices({ limit: this.props.itemsPerPage, skip: 0 });
		}
		if (this.props.count <= 0) {
			this.props.getInvoiceCount();
		}
		this.goToNextPage = this.goToNextPage.bind(this);
		this.searchTermFilterBy = this.searchTermFilterBy.bind(this);
	}
	componentWillReceiveProps(props) {
		if (props.invoices.length < props.count) {
			this.setState({
				showNextBtn: true
			});
		}
		else if (props.invoices.length > 0 && props.count === props.invoices.length) {
			this.setState({
				showNextBtn: false
			});
		}


	}
	searchTermFilterBy(invoice) {
		return `${invoice._id} ${invoice.customer.name.first} ${invoice.customer.name.last} $${invoice.total} ${invoice.type} ${format(invoice.date, 'MM-DD-YYYY')}`;
	}
	goToNextPage() {
		this.props.getLatestInvoices({
			limit: this.props.itemsPerPage,
			skip: this.props.itemsPerPage * this.state.currentPage
		});
		this.setState(state => {
			const incoming = state.currentPage + 1;
			const incomingCount = incoming * this.props.itemsPerPage;
			return {
				currentPage: incoming,
				showNextBtn: !(incomingCount > this.props.count)
			}
		})
	}
	render() {
		return this.props.invoices.length === 0 ? 'There are no items...yet' : (
			<div>
				<div className="search">
					<input type="text" onChange={linkState(this, 'searchTerm')} value={this.state.searchTerm} placeholder="Search" />
				</div>

				<h1>Latest - {this.state.searchTerm}</h1>

				<div className="mb--lrg">
					<SimpleSearch
						items={this.props.invoices}
						filterBy={this.searchTermFilterBy}
						searchTerm={this.state.searchTerm}
						component={(invoice) => <InvoiceRow key={invoice._id} {...invoice} />}
					/>
				</div>
				{this.state.showNextBtn ? <button className="btn" onClick={this.goToNextPage}>Load More</button> : null}
			</div>
		);
	}
}
Dashboard.defaultProps = {
	itemsPerPage: 10
};

const mapStateToProps = (state, ownProps) => ({
	user: state.auth.user,
	invoices: state.invoices.latest,
	count: state.invoices.count
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getLatestInvoices(query) {
			dispatch(fetchLatestInvoices(ownProps.auth.user.token, { query }));
		},
		getInvoiceCount() {
			dispatch(getInvoiceCount(ownProps.auth.user.token));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
