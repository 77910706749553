import { h, render, Component } from 'preact';
import linkState from 'linkstate';
import axios from 'axios';

class AddNewUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            username : this.props.username || '', 
            password : '',
            confirmPassword : '', 
			role : this.props.role || 'user'
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.radioChange = this.radioChange.bind(this);
    }
    handleSubmit (e){
        e.preventDefault();
        if(this.state.password !== this.state.confirmPassword){
            alert('Passwords do not match');
            return;
		}
		const url = this.props.userId ? `/api/user/${this.props.userId}` : '/api/user';
		axios({
			url,
			method: this.props.method,
			headers: {
				Authorization: `Bearer ${this.props.auth.user.token}`
            },
            data : {
                username : this.state.username,
                password: this.state.password,
                role : this.state.role
            }
		})
        .then(({data}) => {
			let type = this.props.method === "put" ? 'updated' : 'added';
            alert(`Successfully ${type} ${data.username}`);
            window.location.reload();
        })
        .catch(err => {
            console.log(err);
            alert(err);
        }); 
        
    }
	radioChange(e) {
		this.setState({ role: e.currentTarget.value });
	}    
    render(){
        return (
            <form onSubmit={this.handleSubmit}>
                <h1>Add New User</h1>
                <div className="input__row">
                    <label htmlFor="username">Username</label>
                    <input name="username" type="text" onChange={linkState(this, 'username')} value={this.state.username} required placeholder="Username" />
                </div>
                <div className="input__row">
                    <div className="columns">
                        <div className="mr--lrg invoice__type">
                            <label for="admin" className="mb--xs u-block">Admin</label>
                            <input className="input__radio" type="radio" id="admin" name="role" value="admin" onChange={this.radioChange} required />
                        </div>

                        <div className="invoice__type">
                            <label for="user" className="mb--xs u-block">User</label>
                            <input className="input__radio" type="radio" id="user" name="role" value="user" onChange={this.radioChange} required />
                        </div>
                    </div>
				</div>                
                <div className="input__row">
                    <label htmlFor="password">Password</label>
                    <input name="password" type="password" onChange={linkState(this, 'password')} value={this.state.password} required placeholder="Password" />
                </div>
                <div className="input__row">
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input name="confirm-password" type="password" onChange={linkState(this, 'confirmPassword')} value={this.state.confirmPassword} placeholder="Confirm Password" required />
                </div>
                <div className="input__row">
                    <button type="submit" className="btn">Submit</button>
                </div>
            </form>
        );
    }
}

AddNewUser.defaultProps = {
	method : 'post'
}
export default AddNewUser;
