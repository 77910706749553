import { h } from 'preact';
import EditingBtns from './EditingBtns';
import { Link } from 'react-router-dom';

const Address = ({ address, city, state, zip }) => {
	if (address && city && state && zip) {
		return (
			<address>
				{address}<br />
				{city}, {state.toUpperCase()}. {zip}
			</address>
		)
	}
	else {
		return null;
	}
}

const Customer = ({ customer, displayChooseOption = false, handleOption, isEditable, handleEdit, handleDelete, showLink = true }) =>
	<div className="invoice__row row customer">
		<div className="col-md-8">
			{showLink ? <Link className="takeover-link" to={`/customer/${customer._id}`}></Link> : null}
			<div className="name">
				{customer.name.first} {customer.name.last}
			</div>
			<div>
				{customer.email}
			</div>
			<div>
				{customer.phone}
			</div>
			<Address {...customer} />
		</div>
		<div className="col-md-4">
			{isEditable &&
				<div className="row">
					<EditingBtns data={customer} handleEdit={handleEdit} handleDelete={handleDelete} />
				</div>
			}
			{displayChooseOption &&
				<button
					onClick={() => {
						handleOption(user);
					}}
				>
					Choose
			</button>}
		</div>
	</div>;

export default Customer;
