import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Template from './Template';
import { getUserByToken, logOutUser } from '../store/actioncreators';

class Authenticated extends Component {
	constructor(props) {
		super(props);
		if (this.props.auth.isAuthenticated && typeof this.props.auth.user.username === 'undefined' || this.props.auth.isAuthenticated && typeof this.props.auth.user.role === 'undefined') {
			this.props.getUserByToken(this.props.auth.user.token);
		}
	}
	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.tokenError !== 'undefined' && nextProps.tokenError !== '') {
			// Sign out user
			nextProps.logOut();
		}
	}
	render() {
		if (this.props.auth.isAuthenticated) {
			if (typeof this.props.auth.user !== 'undefined') {
				return <Template {...this.props} />;
			} else {
				return <h1>Loading</h1>;
			}
		} else {
			return (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: this.props.location }
					}}
				/>
			);
		}
	}
}

const mapStateToProps = state => {
	return { auth: state.auth, tokenErrorMsg: state.auth.tokenErrorMsg };
};

const mapDispatchToProps = dispatch => ({
	logOut() {
		dispatch(logOutUser());
	},
	getUserByToken: function(token) {
		dispatch(getUserByToken(token));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
