import { h } from 'preact';

const Popup = ({ open, children, className = '', onClose, showCloseBtn = true }) =>
	open
		? <div>
				<div>
					<div className="overlay--bg" onclick={onClose}></div>
					<div className={`overlay ${className}`}>
						{showCloseBtn ? <button className="btn--close" onclick={onClose}>x</button> : null}
						{children}
					</div>
				</div>
			</div>
		: null;

export default Popup;
