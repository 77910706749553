import axios from 'axios';
import {
	SIGN_IN_USER_SUCCESS,
	SIGN_IN_USER_ERROR,
	GET_USER_BY_TOKEN_SUCCESS,
	GET_USER_BY_TOKEN_ERROR,
	LOG_OUT_USER,
	GET_LATEST_INVOICES,
	GET_CUSTOMER_INVOICES,
	GET_CUSTOMERS_SUCCESS,
	ADD_CUSTOMER_SUCCESS,
	ADD_CUSTOMER_ERROR,
	GET_CUSTOMER_BY_ID_SUCCESS,
	EDIT_CUSTOMER_SUCCESS,
	EDIT_CUSTOMER_ERROR,
	DELETE_CUSTOMER_SUCCESS,
	DELETE_CUSTOMER_ERROR,
	CACHE_CUSTOMER_BY_ID,
	ADD_INVOICE_SUCCESS,
	ADD_INVOICE_ERROR,
	GET_INVOICE_COUNT,
	GET_INVOICE_BY_ID_SUCCESS,
	GET_INVOICE_BY_ID_ERROR,
	DELETE_INVOICE_BY_ID_SUCCESS,
	DELETE_INVOICE_BY_ID_ERROR,
	EDIT_INVOICE_SUCCESS,
	EDIT_INVOICE_ERROR,
	CLEAR_OUT_INVOICE_MESSAGE,
	CACHE_INVOICE_BY_ID,
	HANDLE_ERROR_MSG,
	RESET_ERROR_MSG,
	GET_COMPANY_INFO_SUCCESS,
	ADD_COMPANY_INFO_SUCCESS,
	EDIT_COMPANY_INFO_SUCCESS,
	GET_USERS_SUCCESS
} from './actions';

export function resetErrorMsg() {
	return {
		type: RESET_ERROR_MSG,
		payload: ""
	};
}

export function handleErrorMsg(err) {
	return {
		type: HANDLE_ERROR_MSG,
		payload: err
	};
}

export function signInUserSuccess(userData) {
	return {
		type: SIGN_IN_USER_SUCCESS,
		payload: userData
	};
}

export function signInUserError(error) {
	return {
		type: SIGN_IN_USER_ERROR,
		payload: error
	};
}

export function signInUser(username, password) {
	return dispatch => {
		axios
			.post('/auth/signin', { username: username, password: password })
			.then(response => {
				dispatch(signInUserSuccess(response.data));
			})
			.catch(err => {
				alert(err.response.data);
			});
	};
}

export function getUserByTokenSuccess(userData) {
	return {
		type: GET_USER_BY_TOKEN_SUCCESS,
		payload: userData
	};
}

export function getUserByTokenError(error) {
	return {
		type: GET_USER_BY_TOKEN_ERROR,
		payload: error
	};
}

export function getUserByToken(token) {
	return dispatch => {
		axios({
			url: '/api/user/me',
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(getUserByTokenSuccess(Object.assign({ token: token }, response.data)));
			})
			.catch(error => {
				dispatch(getUserByTokenError(error.response.data));
			});
	};
}


export function getCompanyInfo(token) {
	return dispatch => {
		axios({
			url: '/api/company',
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch({
					type: GET_COMPANY_INFO_SUCCESS,
					payload: response.data[0]
				});
			})
			.catch(err => {
				dispatch(handleErrorMsg(err));
				setTimeout(() => {
					dispatch(resetErrorMsg(""));
				}, 5000);
			});
	};
}

export function editCompanyInfo(data, token, cb) {
	return dispatch => {
		axios({
			url: `/api/company/${data._id}`,
			method: 'put',
			headers: {
				Authorization: `Bearer ${token}`
			},
			data
		})
			.then(response => {
				dispatch({
					type: EDIT_COMPANY_INFO_SUCCESS,
					payload: response.data
				});
				cb();
			})
			.catch(err => {
				alert('There was an error editing your company info. Please try again - ' + err.message);
			});
	};
}

export function addCompanyInfo(data, token, successCB) {
	return dispatch => {
		axios({
			url: `/api/company/`,
			method: 'post',
			headers: {
				Authorization: `Bearer ${token}`
			},
			data
		})
			.then(response => {
				dispatch({
					type: ADD_COMPANY_INFO_SUCCESS,
					payload: response.data
				});
				if (typeof successCB === "function") successCB(response.data);
			})
			.catch(err => {
				alert('There was an error adding your company info. Please try again - ' + err.message);
			});
	};
}

export function getUsers(token) {
	return dispatch => {
		axios({
			url: '/api/user',
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch({
					type: GET_USERS_SUCCESS,
					payload: response.data
				});
			})
			.catch(err => {
				alert('There was an error getting users - ' + err.message)
			});
	};
}

export function logUserOut() {
	return {
		type: LOG_OUT_USER,
		payload: null
	};
}

// Logs user out from server
export function logOutUser() {
	return dispatch => {
		axios({
			url: '/auth/signout',
			method: 'post'
		})
			.then(() => {
				dispatch(logUserOut());
			})
			.catch(err => {
				alert(`There was an error logging out. Please Try again - ${err.message}`);
			});
	};
}

export function getLatestInvoices(invoiceData) {
	return {
		type: GET_LATEST_INVOICES,
		payload: invoiceData
	};
}

export function fetchLatestInvoices(token, opts = { query: {} }) {
	const additionalQuery = opts.query || {};
	return dispatch => {
		axios({
			url: '/api/invoice',
			method: 'get',
			params: additionalQuery,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(getLatestInvoices(response.data));
			})
			.catch(error => {
				alert("There was an error getting the latest Invoices - " + err.message);
			});
	};
}

export function getInvoiceCount(token) {
	return dispatch => {
		axios({
			url: '/api/invoice/count',
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(({ data }) => {
				dispatch({
					type: GET_INVOICE_COUNT,
					payload: data
				})
			})
			.catch(err => {
				dispatch({
					type: HANDLE_ERROR_MSG,
					payload: err
				});
			});
	}
}


export function getCustomerInvoices(opts, token) {
	const additionalQuery = opts.query || {};
	return dispatch => {
		axios({
			url: '/api/invoice',
			method: 'get',
			params: additionalQuery,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch({
					type: GET_CUSTOMER_INVOICES,
					payload: { _id: opts.query.customer, invoices: response.data }
				});
			})
			.catch(err => {
				alert("There was an error getting the customers invoices - " + err.message);
			});
	};
}


export function getCustomerByIdAction(customerData) {
	return {
		type: GET_CUSTOMER_BY_ID_SUCCESS,
		payload: customerData
	};
}

export function getCustomerById(_id, token) {
	return dispatch => {
		axios({
			url: `/api/customer/${_id}`,
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(getCustomerByIdAction(response.data));
			})
			.catch(err => {
				alert("There was an error getting the customer's invoices - " + err.message);
			});
	};
}

export function getCustomers(customerData) {
	return {
		type: GET_CUSTOMERS_SUCCESS,
		payload: customerData
	};
}

export function fetchCustomers(token) {
	return dispatch => {
		axios({
			url: '/api/customer',
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(getCustomers(response.data));
			})
			.catch(err => {
				alert("There was an error getting the customers - " + err.message);
			});
	};
}

export function addCustomerSuccess(customerData) {
	return {
		type: ADD_CUSTOMER_SUCCESS,
		payload: customerData
	};
}

export function addCustomerError(error) {
	return {
		type: ADD_CUSTOMER_ERROR,
		payload: error
	};
}

export function addCustomer(customerData, token) {
	return dispatch => {
		axios({
			url: '/api/customer',
			method: 'post',
			data: customerData,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(addCustomerSuccess(response.data));
			})
			.catch(error => {
				dispatch(addCustomerError(error.response.data));
			});
	};
}

export function cacheCustomerById(customer) {
	return {
		type: CACHE_CUSTOMER_BY_ID,
		payload: customer
	};
}

export function editCustomerSuccess(customerData) {
	return {
		type: EDIT_CUSTOMER_SUCCESS,
		payload: customerData
	};
}

export function editCustomerError(err) {
	return {
		type: EDIT_CUSTOMER_ERROR,
		payload: err
	};
}

export function clearOutInvoiceMessage(messageType) {
	return {
		type: CLEAR_OUT_INVOICE_MESSAGE,
		payload: messageType
	};
}

export function editCustomer(customerData, token) {
	return dispatch => {
		axios({
			url: `/api/customer/${customerData._id}`,
			method: 'put',
			data: customerData,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(editCustomerSuccess(response.data));
			})
			.catch(error => {
				dispatch(editCustomerError(error.response.data));
			});
	};
}

export function deleteCustomerSuccess(customerData) {
	return {
		type: DELETE_CUSTOMER_SUCCESS,
		payload: customerData
	};
}

export function deleteCustomerError(err) {
	return {
		type: DELETE_CUSTOMER_ERROR,
		payload: err
	};
}

export function deleteCustomer(customerData, token) {
	return dispatch => {
		axios({
			url: `/api/customer/${customerData._id}`,
			method: 'delete',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(deleteCustomerSuccess(response.data));
			})
			.catch(error => {
				dispatch(handleErrorMsg(error.response.data));
				setTimeout(() => {
					dispatch(resetErrorMsg(""));
				}, 5000);
			});
	};
}

export function addInvoiceSuccess(invoiceData) {
	return {
		type: ADD_INVOICE_SUCCESS,
		payload: invoiceData
	};
}

export function addInvoiceError(err) {
	return {
		type: ADD_INVOICE_ERROR,
		payload: err
	};
}

export function addInvoice(invoiceData, token) {
	return dispatch => {
		axios({
			url: '/api/invoice',
			method: 'post',
			data: invoiceData,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(addInvoiceSuccess(response.data));
			})
			.catch(err => {
				dispatch(addInvoiceError(err.response.data));
			});
	};

}

export function editInvoiceSuccess(invoiceData) {
	return {
		type: EDIT_INVOICE_SUCCESS,
		payload: invoiceData
	};
}

export function editInvoiceError(err) {
	return {
		type: EDIT_INVOICE_ERROR,
		payload: err
	};
}

export function editInvoice(invoiceData, token) {
	return dispatch => {
		axios({
			url: `/api/invoice/${invoiceData._id}`,
			method: 'put',
			data: invoiceData,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(editInvoiceSuccess(response.data));
			})
			.catch(err => {
				dispatch(editInvoiceError(err.response.data));
			});
	};
}

export function deleteInvoiceByIdSuccess(invoiceData) {
	return {
		type: DELETE_INVOICE_BY_ID_SUCCESS,
		payload: invoiceData
	};
}

export function deleteInvoiceByIdError(err) {
	return {
		type: DELETE_INVOICE_BY_ID_ERROR,
		payload: err
	};
}

export function deleteInvoiceById(_id, token) {
	return dispatch => {
		axios({
			url: `/api/invoice/${_id}`,
			method: 'delete',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(deleteInvoiceByIdSuccess(response.data));
			})
			.catch(err => {
				dispatch(deleteInvoiceByIdError(err.response.data));
			});
	};

}


export function getInvoiceByIdSuccess(_id) {
	return {
		type: GET_INVOICE_BY_ID_SUCCESS,
		payload: _id
	};
}

export function getInvoiceByIdError(err) {
	return {
		type: GET_INVOICE_BY_ID_ERROR,
		payload: err
	};
}

export function getInvoiceById(_id, token) {
	return dispatch => {
		axios({
			url: `/api/invoice/${_id}`,
			method: 'get',
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(response => {
				dispatch(getInvoiceByIdSuccess(response.data));
			})
			.catch(err => {
				dispatch(getInvoiceByIdError(err.response.data));
			});
	};

}

export function cacheInvoiceById(invoice) {
	return {
		type: CACHE_INVOICE_BY_ID,
		payload: invoice
	};
}

