import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { cacheInvoiceById, deleteInvoiceById, clearOutInvoiceMessage, getInvoiceById, getCompanyInfo } from '../store/actioncreators';
import Invoice from './Invoice';

class InvoiceDetail extends Component {
	constructor(props) {
		super(props);
		if (typeof this.props.invoices[this.props.id] === 'undefined') {
			const invoice = this.props.invoices.latest.filter(invoice => invoice._id === this.props.id);
			if(invoice.length){
				this.props.cacheInvoiceById(invoice[0]);
			} 
			else {
				this.props.getInvoiceById(this.props.id);
			}
		}
		if (this.props.company && typeof this.props.company.name === "undefined") {
			this.props.getCompanyInfo();
		}		
		this.state = { shouldRedirect: false };
		this.deleteInvoice = this.deleteInvoice.bind(this);
	}
	deleteInvoice() {
		const msg = "Are you sure you want to delete this invoice?";
		if(confirm(msg)){
			this.props.deleteInvoiceById(this.props.id);
		}
	}
	componentWillReceiveProps(props) {
		if (props.deleteInvoiceSuccessMsg !== "") {
			this.setState({ shouldRedirect: true });
			this.props.clearOutInvoiceMessage("deleteInvoiceSuccessMsg");
		}
		else if (props.deleteInvoiceErrorMsg !== "") {
			alert(props.deleteInvoiceErrorMsg);
			this.props.clearOutInvoiceMessage("deleteInvoiceErrorMsg");
		}
		else if (this.props.errorMsg !== ""){
			alert(this.props.errorMsg + ' - Try Refreshing your page');
		}
	}
	render() {
		if (this.state.shouldRedirect) {
			return <Redirect to="/" />
		}
		else if (typeof this.props.invoices[this.props.id] === 'undefined') {
			return <div>Loading...</div>
		}
		else {
			return <Invoice {...this.props.invoices[this.props.id]} company={this.props.company} onDelete={this.deleteInvoice} />;
		}
	}
}

const mapStateToProps = state => ({
	company : state.company.info,
	invoices: state.invoices,
	deleteInvoiceSuccessMsg: state.invoices.deleteInvoiceSuccessMsg,
	deleteInvoiceErrorMsg: state.invoices.deleteInvoiceErrorMsg,
	errorMsg : state.errors.errorMsg
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getCompanyInfo(){
			dispatch(getCompanyInfo(ownProps.auth.user.token));
		},
		getInvoiceById(id) {
			dispatch(getInvoiceById(id, ownProps.auth.user.token));
		},		
		deleteInvoiceById(id) {
			dispatch(deleteInvoiceById(id, ownProps.auth.user.token));
		},
		cacheInvoiceById(invoiceData) {
			dispatch(cacheInvoiceById(invoiceData));
		},
		clearOutInvoiceMessage(type) {
			dispatch(clearOutInvoiceMessage(type));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
