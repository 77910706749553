import { h, render } from 'preact';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'preact/devtools';

import '../../scss/libs/bootstrap.scss';
import '../../scss/layout.scss';
import '../../scss/styles.scss';

const container = document.getElementById('app');

const renderApp = () => {
	render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
		container,
		container.lastChild
	);
};

renderApp();

if (module.hot) {
	module.hot.accept('./App', () => {
		renderApp();
	});
}
