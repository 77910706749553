import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

let isAuth = typeof window === 'object' && window.__PRELOADED_STATE__ ? window.__PRELOADED_STATE__ : {};
// Dev tools settings:
// compose just feeds all actions into the applyMiddlewear, & then devTools - // f => f  is an "identity function". basically does nothing
// If you dont need dev tools remove the typeof section
const store = createStore(
	reducer,
	isAuth,
	compose(
		applyMiddleware(thunk),
		typeof window === 'object' && typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f
	)
);

export default store;
