import { h, render, cloneElement, Component } from 'preact';
import Nav from './Nav';

class Template extends Component {
	constructor(props){
		super(props);
		this.state = {
			isActive : false
		};
		this.setNavClass = this.setNavClass.bind(this);
	}
	setNavClass(){
		console.log('CLIKKKK');
		this.setState({
			isActive : !this.state.isActive
		});
	}
	render(){
		const props = this.props;
		const {isActive} = this.state;
		const menuClass = isActive ? "nav--active" : "";
		return <div className="container-fluid">
        <div className="row">
          <div className="nav__container">
            <button className="hamburger clearfix" onClick={this.setNavClass}>
			<div className="right">
				<div className="hamburger__line"></div>
				<div className="hamburger__line"></div>
				<div className="hamburger__line"></div>
			</div>

            </button>
            <Nav className={menuClass} username={props.auth.user.username} user={props.auth.user} />
          </div>
          <main className="main-content">
            {props.children.map(child => cloneElement(child, ...props))}
          </main>
        </div>
      </div>;
	}
}


export default Template;
