import { h, Component } from 'preact';
import { connect } from 'react-redux';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import { signInUser } from '../store/actioncreators';

class SignIn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidUsername: true,
			isValidPassword: true,
			username: '',
			password: ''
		};
		this.handleClickEvent = this.handleClickEvent.bind(this);
		this.handleUsername = this.handleUsername.bind(this);
		this.handlePassword = this.handlePassword.bind(this);
	}
	componentWillUnmount() {
		this.setState(
			Object.assign(
				{},
				{
					isValidUsername: false,
					isValidPassword: false,
					username: '',
					password: ''
				}
			)
		);
	}
	componentWillMount() {
		if (this.props.isAuthenticated) {
			this.props.history.push('/');
		}
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.isAuthenticated) {
			nextProps.history.push('/');
		}
	}
	handleClickEvent(e) {
		e.preventDefault();
		if (this.state.isValidUsername && this.state.isValidPassword) {
			this.props.signInUser(this.state.username, this.state.password);
			this.setState(Object.assign({}, this.state, { isFetching: true }));
		}
	}
	handleUsername(e) {
		let isValid = isAlphanumeric(e.target.value);
		this.setState({
			username: e.target.value,
			isValidUsername: isValid
		});
	}
	handlePassword(e) {
		let isValid = /^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]/g.test(e.target.value);
		this.setState(
			Object.assign({}, this.state, {
				password: e.target.value,
				isValidPassword: isValid
			})
		);
	}
	render() {
		let serverError = '';
		if (this.props.errorMsg && this.props.errorMsg !== '') {
			serverError = (
				<h1>
					{this.props.errorMsg}, please try again
				</h1>
			);
		}
		// let borderColor = this.state.isValidPassword ? '1px solid green' : '1px solid red';
		return (
			<div className="sign-in">
				<div className="container__xs">
					<h1 className="login">Enter your login info</h1>
					<form onSubmit={this.handleClickEvent}>
						<div className="input__row">
							<input
								onChange={this.handleUsername}
								value={this.state.username}
								type="text"
								placeholder="username"
								required="required"
							/>
						</div>
						<div className="input__row">
							<input
								onChange={this.handlePassword}
								placeholder="password"
								value={this.state.password}
								type="password"
								required="required"
							/>
						</div>
						<button type="submit" className="btn">
							Log In
					</button>
					</form>
					{serverError}
				</div>

			</div>
		);
	}
}

/* ownProps get passed down from the parent */

const mapStateToProps = (state, ownProps) => {
	return {
		errorMsg: state.auth.errorMsg,
		isAuthenticated: state.auth.isAuthenticated
	};
};

const mapDispatchToProps = (dispatch) => ({
	signInUser: function (username, password) {
		dispatch(signInUser(username, password));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
