import { h, Component } from 'preact';
import { connect } from 'react-redux';
import format from "date-fns/format";
import linkState from 'linkstate';
import { fetchCustomers } from '../store/actioncreators';
import InvoiceInput from './InvoiceInput';
import serialize from 'form-serialize';
import Select from 'react-select';
import roundMoney from '../utils/roundMoney';

class InvoiceForm extends Component {
	constructor(props) {
		super(props);

		if (this.props.customers.length === 0) {
			this.props.getCustomers();
		}

		const items = this.props.items.length ? this.props.items : new Array(this.props.numOfInputs).fill({});
		const total = this.props.total || 0;

		this.state = {
			customer: this.props.customer && this.props.customer._id || '',
			date : this.props.date || undefined, 
			title : this.props.title || undefined,
			items,
			total,
			numOfInputs: this.props.numOfInputs,
			type: this.props.type,
			selectedOption: null
		};
		this.formEl = null;
		this.submitHandler = this.submitHandler.bind(this);
		this.handleAddAnotherInput = this.handleAddAnotherInput.bind(this);
		this.serializeForm = this.serializeForm.bind(this);
		this.onPrice = this.onPrice.bind(this);
		this.radioChange = this.radioChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleDate = this.handleDate.bind(this);
	}
	handleDate(e){
		let value = e.currentTarget.value;
		const regex = new RegExp("^([0-9]{4})-([0-9]{2})-([0-9]{2})");
		const dateMatch = value.match(regex);
		const year = dateMatch[1];
		const month = dateMatch[2];
		const day = dateMatch[3];

		const date = new Date(year, month - 1, day);
		
		this.setState({
			date
		});
	}
	handleChange(selectedOption) {
		this.setState({ customer: selectedOption.value, selectedOption });
	}
	componentWillReceiveProps(props, oldProps) {
		if (props.addInvoiceSuccessMsg !== "") {
			alert(`Successfully added ${props.addInvoiceSuccessMsg}`);
			window.location = `/invoice/${props.addInvoiceSuccessMsg}`;
		}
		if (props.editInvoiceSuccessMsg !== "") {
			alert(`Successfully edited ${props.editInvoiceSuccessMsg}`);
			window.location = `/invoice/${props.editInvoiceSuccessMsg}`;
		}
	}
	shouldComponentUpdate(props, state) {
		if (props.addInvoiceSuccessMsg !== "" || props.editInvoiceSuccessMsg !== "") {
			return false;
		}
		return true;
	}
	serializeForm() {
		const data = serialize(this.formEl, { hash: true });
		console.log('d', data);
		let items = [];
		if (Array.isArray(data.desc)) {

			items = data.desc.map((price, i) => {
				let fPrice = undefined;
				if (data.price && data.price[i]) {
					const parse = Number.parseFloat(data.price[i]);
					fPrice = parse || undefined;
				}

				return {
					desc: data.desc && data.desc[i] || null,
					price: fPrice,
					qty: data.qty && data.qty[i] ? Number.parseInt(data.qty[i]) : undefined
				}
			});
		}
		else {
			if (Number.isNaN(data.qty)) {
				delete data.qty;
				items = [data];
			}
			else {
				items = [data]
			}
		}
		return { items, type: data.type };
	}
	submitHandler(e) {
		e.preventDefault();
		const customerId = this.state.customer || (this.props.customer && this.props.customer._id);
		const { items, type } = this.serializeForm();
		let itemsF = items.filter(({ desc }) => desc !== null);
		if (!customerId) {
			alert('Please select a customer');
			return;
		}
		
		const total = Number.parseFloat(this.state.total);
		const value = {
			title : this.state.title,
			date : this.state.date,
			customer: customerId,
			user: this.props.auth.user._id,
			items: itemsF,
			total: roundMoney(total),
			type: type || this.props.type
		};
		this.props.onSubmit(value);

	}
	handleAddAnotherInput(data) {
		this.setState((state) => ({
			numOfInputs: state.numOfInputs + 1,
			items: state.items.concat([{}])
		}));
	}
	onPrice() {
		let total = 0;
		const { items } = this.serializeForm();
		items.forEach(({ price, qty }) => {
			const calculate = (price || 0) * (qty || 1);
			total += calculate;
			total = roundMoney(total);
		});
		this.setState((state) => {
			return { total }
		});
	}
	radioChange(e) {
		this.setState({ type: e.currentTarget.value });
	}
	render() {
		const { numOfInputs } = this.state;
		if (this.props.customers.length === 0) return "There are no Customers..."
		const name = this.props.customer && this.props.customer.name ? `${this.props.customer.name.first} ${this.props.customer.name.last}` : '';
		const options = this.props.customers.map(({ name, _id, address }) => {
			return { 'value': _id, 'label': `${name.first} ${name.last} - ${address}` }
		});

		return (
			<form className="invoice__form" onSubmit={this.submitHandler} ref={el => {
				this.formEl = el;
			}}>
				<div className="input__row">
					<label>Customer</label>
					<Select
						value={this.state.selectedOption}
						onChange={this.handleChange}
						options={options}
						defaultInputValue={name}
						required
					/>
				</div>
				<div className="input__row">
					<label>Date</label>
					<input type="date" onChange={this.handleDate} />
				</div>
				<div className="input__row">
					<label>Project Name</label>
					<input type="text" name="title" placeholder="Project Name Ex. Brea Carwash" onChange={linkState(this, 'title')} value={this.state.title} />
				</div>				
				<div className="row">
					<div className={`col-md-6 ${this.props.showType ? '' : 'u-visibility-hidden'}`}>
						<div className="input__row">
							<div className="columns">
								<div className="mr--lrg invoice__type">
									<label for="invoice" className="mb--xs u-block">Invoice</label>
									<input className="input__radio" type="radio" id="invoice" name="type"
										checked={this.state.type === "invoice"} value="invoice" onChange={this.radioChange} />
								</div>

								<div className="invoice__type">
									<label for="proposal" className="mb--xs u-block">Proposal</label>
									<input className="input__radio" type="radio" id="proposal" name="type" value="proposal" checked={this.state.type === "proposal"} onChange={this.radioChange} />
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					this.state.items.map((data, i) =>
						<InvoiceInput key={i} initialState={data} onClick={this.handleAddAnotherInput} showBtn={i === numOfInputs - 1} onPrice={this.onPrice} />
					)
				}

				<div className="input__row">
					<label htmlFor="price">Total</label>
					<input type="text" name="total" placeholder="total" onInput={linkState(this, 'total')} value={this.state.total} required />
				</div>

				<button className="btn" type="submit">
					{this.props.btnTitle}
				</button>
				{this.props.addInvoiceSuccessMsg}
				{this.props.addInvoiceErrorMsg}
				{this.props.editInvoiceSuccessMsg}
				{this.props.editInvoiceErrorMsg}
			</form >
		);
	}
}

InvoiceForm.defaultProps = {
	btnTitle: 'Add Invoice',
	numOfInputs: 3,
	items: [],
	onSubmit: () => { },
	showType: false,
	type: 'invoice'
}

const mapStateToProps = state => ({
	customers: state.customers.latest,
	addCustomerErrorMsg: state.customers.addCustomerErrorMsg,
	addInvoiceSuccessMsg: state.invoices.addInvoiceSuccessMsg,
	addInvoiceErrorMsg: state.invoices.addInvoiceErrorMsg,
	editInvoiceSuccessMsg: state.invoices.editInvoiceSuccessMsg,
	editInvoiceErrorMsg: state.invoices.editInvoiceErrorMsg
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getCustomers() {
			dispatch(fetchCustomers(ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
