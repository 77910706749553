import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { cacheCustomerById, getCustomerById, getCustomerInvoices } from '../store/actioncreators';
import Customer from './Customer';
import InvoiceRow from './InvoiceRow';
import SimpleSearch from './SimpleSearch';
import linkState from 'linkstate';
import format from 'date-fns/format';

class CustomerDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: ''
		};
		if (typeof this.props.customers[this.props.id] === 'undefined') {
			const customers = this.props.customers.latest.filter(customer => customer._id === this.props.id);
			if (customers.length) {
				this.props.cacheCustomerById(customers[0]);
			}
			else {
				this.props.getCustomerById(this.props.id);
			}
		}
		if (this.props.invoices.length <= 0) {
			// Also get the invoices
			this.props.getCustomerInvoices(this.props.id);
		}
		this.filterBy = this.filterBy.bind(this);

	}
	filterBy(invoice) {
		return `${invoice._id} $${invoice.total} ${invoice.total} ${invoice.type} ${format(invoice.date, 'MM-DD-YYYY')}`;
	}
	render() {
		if (typeof this.props.customers[this.props.id] === 'undefined' && this.props.invoices.length <= 0) {
			return <div>Loading...</div>
		}

		return <div>
			<div className="search">
				<input type="text" onChange={linkState(this, 'searchTerm')} value={this.state.searchTerm} placeholder="Search" />
			</div>
			<Customer customer={this.props.customers[this.props.id]} isEditable={false} showLink={false} />
			<h3>Invoices:</h3>
			<SimpleSearch items={this.props.invoices} searchTerm={this.state.searchTerm} filterBy={this.filterBy} component={invoice => <InvoiceRow  {...invoice} />} />
		</div>


	}
}

const mapStateToProps = (state, ownProps) => ({
	customers: state.customers,
	invoices: state.customers.invoices[ownProps.id] || []
});

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getCustomerById(_id) {
			dispatch(getCustomerById(_id, ownProps.auth.user.token));
		},
		cacheCustomerById(customerData) {
			dispatch(cacheCustomerById(customerData));
		},
		getCustomerInvoices(_id) {
			dispatch(getCustomerInvoices({ query: { customer: _id } }, ownProps.auth.user.token))
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);