// playground requires you to assign document definition to a variable called dd
const border = [false, false, false, false];

const definition = ({ _id, name, email, phone, date, totalPrice, address1, address2, items = [], invoiceType, companyAddress = "", companyAddress2 = "", companyName, license, companyPhone = "", companyEmail = "", projectName }) => ({
	content: [
		{ text: companyName, style: 'header', margin: [0, 0, 0, 5] },
		{ text: companyAddress + ' ' + companyAddress2, alignment: 'center' },
		{ text: companyPhone, alignment: 'center' },
		{ text: invoiceType, style: 'header', alignment: 'left' },
		{ text: license !== "" ? `License # ${license}` : '' },
		{ text: companyEmail },
		{
			columns: [
				{
					// auto-sized columns have their widths based on their content
					width: '50%',
					text: 'BILLED TO:',
					style: "subheader",
				},
				{
					// star-sized columns fill the remaining space
					// if there's more than one star-column, available width is divided equally
					width: '50%',
					text: `${invoiceType} #`,
					alignment: 'right',
					style: "subheader"
				}
			]
		},
		{
			columns: [
				{
					// auto-sized columns have their widths based on their content
					width: '50%',
					columns: [
						[{ text: name, margin: [0, 5, 0, 0] },
						projectName,
						address1,
						address2,
						{ text: email, margin: [0, 15, 0, 0] }, phone]
						
					],
						
				},
				{
					// star-sized columns fill the remaining space
					// if there's more than one star-column, available width is divided equally
					width: '50%',
					columns: [[_id, { text: "DATE:", style: "subheader", margin: [0, 10, 0, 0] }, date]],
					alignment: 'right'
				}
			]
		},
		{
			style: 'tableHeader',
			table: {
				widths: ['*', 80, 80],
				body: [
					['Description', 'Qty', 'Price']
				]
			}
		},
		{
			style: "tableExample",
			table: {
				widths: ['*', 80, 80],
				body: items
			},
		},
		{
			style: "totalTable",
			table: {
				widths: ['*', 80, 80],
				body: [
					[{ text: "", border }, { text: 'TOTAL', color: '#000', border }, { text: totalPrice, border, color: '#000' }]
				]
			}
		},
		{
			text: `${invoiceType.split("").map((l, i) => i <= 0 ? l : l.toLowerCase()).join("")} amount includes parts and labor`,
			margin: [0, 0, 0, 10]
		},
		{
			text: `Please make checks payable to ${companyName}`
		},
		{
			text: "Payment is due within 7 days of receipt to avoid late fees"
		}
	],
	styles: {
		header: {
			fontSize: 18,
			bold: true,
			width: '100%',
			display: 'block',
			color: "#000",
			alignment: 'center',
			margin: [0, 10, 0, 5]
		},
		subheader: {
			bold: true,
			color: "#000",
			margin: [0, 15, 0, 0],
		},
		tableHeader: {
			margin: [0, 15, 0, 0],
			fillColor: '#000',
			color: "#fff",
			alignment: 'left'
		},
		tableExample: {
			margin: [0, 0, 0, 0],
			padding: 5
		},
		totalTable: {
			bold: true,
			margin: [0, 10, 0, 0]
		}
	},
	defaultStyle: {
		color: "#333",
		fontFamily : 'Raleway-v4013-Regular.otf',
		fontSize : 12
	}
});

module.exports = definition;