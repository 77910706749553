import { h } from 'preact';
export default ({ items, filterBy, searchTerm, component, className, noItems = "There are no items that match" }) => {
	let filtered = items
		.filter(item => filterBy(item)
			.toLowerCase()
			.indexOf(searchTerm.toLowerCase()) >= 0)
		.map((item) => component(item));
	if(filtered.length === 0) filtered = `${noItems} ${searchTerm}`;

	return <div className={className}>{filtered}</div>
}