import { h } from 'preact';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import definition from '../pdf/pdfDefinition';
import formatMoney from '../utils/formatMoney';

const InvoiceItem = ({ desc, price, qty = '', count }) => {
	return (
		<div className="row invoice__row">
			<div className="col-md-1">
				{count}
			</div>
			<div className="col-md-7">
				{desc}
			</div>
			<div className="col-md-1">
				{qty}
			</div>
			<div className="col-md-3">
				{formatMoney(price)}
			</div>
		</div>
	);
};


const openPDF = ({ _id, name, email, phone, date, totalPrice, items, address1, address2, invoiceType, companyName, license, companyAddress, companyAddress2, companyEmail, companyPhone, projectName, displayId }) => () => {


	pdfMake.createPdf(definition({
		_id : displayId || _id,
		name,
		email,
		phone,
		date: format(date, 'MM/DD/YYYY'),
		totalPrice,
		address1,
		address2,
		companyEmail,
		companyAddress,
		companyAddress2,
		companyPhone,
		items,
		invoiceType: invoiceType.toUpperCase(),
		companyName,
		license,
		projectName
	})).open();
}

const ProjectName = ({ title }) => {
return (
	<div className="info__row">
		<b>Project Name:</b>
		<div style="margin-top:5px;">{title}</div>
	</div>
	);
}

const Invoice = ({
  _id,
  customer,
  date,
  items,
  title,
  total,
  type,
  onDelete,
  company = {},
  displayId
}) => {
  const border = [true, true, true, true];
  const margin = [2, 2, 2, 2];
  const address2 =
    customer.city && customer.state
      ? `${customer.city}, ${customer.state.toUpperCase()}. ${customer.zip ||
          ""}`
      : "";
  const companyAddress2 =
    company && company.city && company.state && company.zip
      ? `${company.city}, ${company.state.toUpperCase()}. ${company.zip}`
      : "";
  const pdf = openPDF({
	_id,
	displayId, 
    invoiceType: type,
    name: `${customer.name.first} ${customer.name.last}`,
    email: customer.email,
    phone: customer.phone ? customer.phone : "",
    projectName: title,
    address1: customer.address,
    address2,
    companyAddress: company && company.address,
    companyAddress2,
    companyEmail: company && company.email,
    date,
    totalPrice: formatMoney(total),
    items: items.map(({ desc, qty, price }) => [
      { text: desc, margin, border },
      { text: qty || "", margin, border },
      { text: formatMoney(price) ? formatMoney(price) : "", margin, border }
    ]),
    companyName: company.name || "",
    license: company.license || "",
    companyPhone: (company && company.phone) || ""
  });
  return (
    <div>
      <div className="top-nav-header clearfix">
        <div className="left">
          <button className="btn btn--secondary" onClick={pdf}>
            Open PDF
          </button>
        </div>
        <div className="right">
          <div class="columns">
            <div className="btn-stack">
              <Link className="btn btn--left" to={`/edit/invoice/${_id}`}>
                Edit
              </Link>
            </div>
            <div className="btn-stack">
              <button
                className="btn btn--secondary btn--right"
                onClick={onDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <h1>
        {type} # {_id}
      </h1>
      <p>
        Display ID : {displayId || "NA"}
      </p>

      <time>
        Date: {format(date, "MM/DD/YYYY")}
      </time>

      <div className="info">
        <p className="info__row">
          <Link to={`/customer/${customer._id}`}>
            {customer.name.first} {customer.name.last}
          </Link>
        </p>
        <p className="info__row">
          {customer.email}
        </p>
        <address className="info__row">
          {customer.address} <br />
          {address2}
        </address>
        <p className="info__row">
          {customer.phone}
        </p>
      </div>
      {title ? <ProjectName title={title} /> : null}

      <div>
        <h2>Invoice Details</h2>
        <div className="row invoice__row">
          <div className="col-md-1 bold">No.</div>
          <div className="col-md-7 bold">Item Description</div>
          <div className="col-md-1 bold">QTY</div>
          <div className="col-md-3 bold">Price</div>
        </div>
        {items.map((item, index) =>
          <InvoiceItem {...item} count={index + 1} />
        )}

        <div className="row invoice__row">
          <div className="col-md-1" />
          <div className="col-md-7" />
          <div className="col-md-1" />
          <div className="col-md-3 ">
            <b>Total:</b> {formatMoney(total)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
