import { h, Component } from "preact";
import isEmail from "validator/lib/isEmail";
import linkState from "linkstate";

class CustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName:
        typeof this.props.customer.name !== "undefined"
          ? this.props.customer.name.first
          : "",
      lastName:
        typeof this.props.customer.name !== "undefined"
          ? this.props.customer.name.last
          : "",
      address: this.props.customer.address || "",
      city: this.props.customer.city || "",
      state: this.props.customer.state || "",
      zip: this.props.customer.zip || "",
      email: this.props.customer.email || "",
      phone: this.props.customer.phone || "",
      isNameValid: false,
      isAddressValid: false,
      isEmailValid: false,
      isPhoneValid: false
    };

    this.checkIfValid = this.checkIfValid.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.checkIfValid();
  }
  checkIfValid() {
	  const isAlpha = (str) => str.match(/[a-zA-Z\s\/s]+/ig);
	  
	  const { firstName, lastName } = this.state;
	  const isNameValid = isAlpha(firstName.trim("")) && isAlpha(lastName.trim(""));
	
    this.setState({
      isNameValid
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.checkIfValid();

    const { isNameValid } = this.state;

    if (isNameValid) {
      this.props.handleSubmit(
        Object.assign({}, this.props.customer, {
          name: {
            first: this.state.firstName.trim(""),
            last: this.state.lastName.trim("")
          },
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          email: this.state.email,
          phone: this.state.phone
        })
      );
    } else {
      alert("Name is not valid, please try again");
    }
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="input__row">
          <label>Name</label>
          <input
            type="text"
            placeholder="First Name"
            value={this.state.firstName}
            onInput={linkState(this, "firstName")}
            required
          />
        </div>
        <div className="input__row">
          <input
            type="text"
            placeholder="Last Name"
            value={this.state.lastName}
            onInput={linkState(this, "lastName")}
            required
          />
        </div>
        <div className="input__row">
          <label>Address</label>
          <input
            type="text"
            placeholder="Address"
            value={this.state.address}
            onInput={linkState(this, "address")}
          />
        </div>
        <div className="input__row">
          <input
            type="text"
            placeholder="City"
            value={this.state.city}
            onInput={linkState(this, "city")}
          />
        </div>
        <div className="input__row">
          <input
            type="text"
            placeholder="State"
            value={this.state.state}
            onInput={linkState(this, "state")}
            pattern="^[a-zA-Z]{2}"
          />
        </div>
        <div className="input__row">
          <input
            type="text"
            placeholder="Zip"
            value={this.state.zip}
            onInput={linkState(this, "zip")}
            pattern="^[0-9]+"
          />
        </div>
        <div className="input__row">
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            value={this.state.email}
            onInput={linkState(this, "email")}
          />
        </div>
        <div className="input__row">
          <label>Phone</label>
          <input
            type="text"
            placeholder="Phone Number - Ex. 213-123-1234"
            value={this.state.phone}
            onInput={linkState(this, "phone")}
            pattern="(1-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
          />
        </div>
        <button className="btn" type="submit">
          Save
        </button>
      </form>
    );
  }
}

CustomerForm.defaultProps = {
  customer: {}
};

export default CustomerForm;
