import { h } from 'preact';

const EditingBtns = ({ data, handleEdit, handleDelete }) =>
	<div className="clearfix">
		<div className="right">
			<div class="columns">
				<div className="btn-stack--sm">
					<button
						className="btn btn--left"
						onClick={() => {
							handleEdit(data);
						}}
					>
						Edit
					</button>
				</div>
				<div className="btn-stack--sm">
					<button
						className="btn btn--secondary btn--right"
						onClick={() => {
							handleDelete(data);
						}}
					>
						Delete
					</button>
				</div>
			</div>
		</div>
	</div>

export default EditingBtns;