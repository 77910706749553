// Auth
export const SIGN_IN_USER_SUCCESS = 'SIGN_IN_USER_SUCCESS';
export const SIGN_IN_USER_ERROR = 'SIGN_IN_USER_ERROR';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const GET_USER_BY_TOKEN_SUCCESS = 'GET_USER_BY_TOKEN_SUCCESS';
export const GET_USER_BY_TOKEN_ERROR = 'GET_USER_BY_TOKEN_ERROR';

// Invoices
export const GET_LATEST_INVOICES = 'GET_LATEST_INVOICES';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_ERROR = 'ADD_INVOICE_ERROR';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_ERROR = 'GET_INVOICE_BY_ID_ERROR';
export const EDIT_INVOICE_SUCCESS = 'EDIT_INVOICE_SUCCESS';
export const EDIT_INVOICE_ERROR = 'EDIT_INVOICE_ERROR';
export const DELETE_INVOICE_BY_ID_SUCCESS = 'DELETE_INVOICE_BY_ID_SUCCESS';
export const DELETE_INVOICE_BY_ID_ERROR = 'DELETE_INVOICE_BY_ID_ERROR';
export const CLEAR_OUT_INVOICE_MESSAGE = 'CLEAR_OUT_INVOICE_MESSAGE';
export const CACHE_INVOICE_BY_ID = 'CACHE_INVOICE_BY_ID';
export const GET_INVOICE_COUNT = 'GET_INVOICE_COUNT';

// Customers
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_ERROR = 'ADD_CUSTOMER_ERROR';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_ERROR = 'EDIT_CUSTOMER_ERROR';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';
export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';
export const CACHE_CUSTOMER_BY_ID = 'CACHE_CUSTOMER_BY_ID';
export const GET_CUSTOMER_INVOICES = 'GET_CUSTOMER_INVOICES';

// Company 
export const GET_COMPANY_INFO_SUCCESS = "GET_COMPANY_INFO_SUCCESS";
export const EDIT_COMPANY_INFO_SUCCESS = "EDIT_COMPANY_INFO_SUCCESS";
export const ADD_COMPANY_INFO_SUCCESS = "ADD_COMPANY_INFO_SUCCESS";

// USERS 
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

// ERROR 
export const RESET_ERROR_MSG = 'RESET_ERROR_MSG';
export const HANDLE_ERROR_MSG = 'HANDLE_ERROR_MSG';