import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { logOutUser, getCompanyInfo, editCompanyInfo, addCompanyInfo } from '../store/actioncreators';
import linkState from 'linkstate';
import axios from 'axios';
import Popup from './Popup';

class MyAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			confirmPassword: '',
			name: this.props.company && this.props.company.name || '',
			license: this.props.company && this.props.company.license || '',
			email: this.props.company && this.props.company.email || '',
			phone: this.props.company && this.props.company.phone || '',
			address : this.props.company && this.props.company.address || '',
			city: this.props.company && this.props.company.city || '',
			state: this.props.company && this.props.company.state || '',
			zip: this.props.company && this.props.company.zip || '',
			downloadPath: this.props.company && this.props.company.downloadPath || "",
			showPasswordForm: false,
			showEditForm: false
		};
		this.showPasswordForm = this.showPasswordForm.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.addCompanyInfo = this.addCompanyInfo.bind(this);
		this.editCompanyInfo = this.editCompanyInfo.bind(this);
		this.showEditForm = this.showEditForm.bind(this);
		this.companyInfo = this.companyInfo.bind(this);

		if (!this.props.company.name) {
			console.log('I SHOULD GET HERE, req', this.props.getCompanyInfo);
			this.props.getCompanyInfo();
		}
	}
	componentWillReceiveProps(nextProps) {
		
		console.log('nextProps.customer', nextProps.company);
		console.log('nextProps', nextProps);
		let update = {};
		if (nextProps.company) {
			Object.entries(nextProps.company).forEach(([key, value]) => {
				console.log('**************');
				console.log('key', key);
				console.log('value', value);
				console.log('this.state[key]', this.state[key]);
				if(typeof this.state[key] !== "undefined" && this.state[key] !== value){
					update[key] = value;
				}
			});
		}
		console.log('update', update);
		
		this.setState(update);
	}
	showPasswordForm(e) {
		e.preventDefault();
		this.setState({
			showPasswordForm: !this.state.showPasswordForm
		});
	}
	handlePasswordChange() {
		if (this.state.password === "" || this.state.password !== this.state.confirmPassword) {
			alert('Passwords do not match');
			return;
		}
		axios({
			url: `/api/user/${this.props.auth.user._id}`,
			method: 'put',
			data: {
				password: this.state.password
			},
			headers: {
				Authorization: `Bearer ${this.props.auth.user.token}`
			}
		})
			.then(() => {
				alert("Successfully updated your password");
				this.props.logOutUser();
			})
			.catch((err) => {
				alert(`Something went wrong please try again - ${err.message}`);
			})
	}
	companyInfo(e) {
		
		if (this.props.company && (typeof this.props.company.name === "undefined" || this.props.company.name === "")) {
			this.addCompanyInfo(e);
		}
		else {
			this.editCompanyInfo(e);
		}
	}
	addCompanyInfo(e) {
		e.preventDefault();
		const data = {
			name: this.state.name,
			license: this.state.license,
			user: this.props.auth.user._id,
			phone: this.state.phone,
			email: this.state.email,
			address: this.state.address,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			downloadPath: this.state.downloadPath
		}
		console.log('data', data);
		this.props.addCompanyInfo(data, () => {
			alert('Successfully added Company info');
			this.showEditForm(e);
		});
	}
	editCompanyInfo(e) {
		e.preventDefault();
		console.log('EDIT COMPANY');
		console.log('this.state.license', this.state.license);
		const data = {
			name: this.state.name,
			license: this.state.license,
			_id: this.props.company._id,
			phone: this.state.phone,
			email: this.state.email,
			address: this.state.address,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			downloadPath: this.state.downloadPath
		}
		console.log('data', data);
		this.props.editCompanyInfo(data, () => {
			alert('Successfully edited Company info');
			this.showEditForm(e);
		});
	}
	showEditForm(e) {
		e.preventDefault();
		console.log('showEditForm');
		console.log('this.state', this.state);
		this.setState({
			showEditForm: !this.state.showEditForm
		})
	}
	render() {
		console.log('rerender', this.props.company);
		return <div>
			<h1>My Account</h1>
			<div className="input__row">
				<label>My Username</label>
				{this.props.auth.user.username}
			</div>
			<div className="input__row">
				<label>Role</label>
				{this.props.auth.user.role}
			</div>
			<div className="input__row">
				<a href="#" onClick={this.showPasswordForm}>
					Click here to change your password
				</a>
			</div>
			<div className="input__row">
				<label>Invoice Download Path</label>
				<p>{typeof this.props.company.downloadPath !== "undefined" ? this.props.company.downloadPath : 'Nothing to show'}</p>
			</div>			
			<div className="input__row">
				<label>Company Name</label>
				<p>{typeof this.props.company.name !== "undefined" ? this.props.company.name : 'Nothing to show'}</p>
			</div>
			<div className="input__row">
				<label>License #</label>
				<p>{typeof this.props.company.license !== "undefined" ? this.props.company.license : 'Nothing to show'}</p>
			</div>
			<div className="input__row">
				<label>Email</label>
				<p>{typeof this.props.company.email !== "undefined" ? this.props.company.email : 'Nothing to show'}</p>
			</div>
			<div className="input__row">
				<label>Phone</label>
				<p>{typeof this.props.company.phone !== "undefined" ? this.props.company.phone : 'Nothing to show'}</p>
			</div>
			<div className="input__row">
				<label>Address</label>
				<address>
					{typeof this.props.company.address !== "undefined" ? this.props.company.address : ''}
					<br />
					{typeof this.props.company.city !== "undefined" ? `${this.props.company.city}, ` : ''}
					{typeof this.props.company.state !== "undefined" ? `${this.props.company.state.toUpperCase()}. ` : ''}
					{typeof this.props.company.zip !== "undefined" ? this.props.company.zip : ''}
				</address>

			</div>
			<div className="input__row">
				<a href="#" onClick={this.showEditForm}>
					Click here to add or change your Company Info
				</a>
			</div>


			<Popup open={this.state.showPasswordForm} className="col-md-4" onClose={this.showPasswordForm}>
				<div className="mb--md">
					<div className="input__row">
						<label>Password</label>
						<input type="password" placeholder="Password" onChange={linkState(this, 'password')} />
					</div>
					<div className="input__row">
						<label>Confirm Password</label>
						<input type="password" placeholder="Confirm password" onChange={linkState(this, 'confirmPassword')} />
					</div>
					<div className="input__row">
						<button type="button" className="btn" onClick={this.handlePasswordChange}>
							Update Password
						</button>
					</div>

				</div>
			</Popup>

			<Popup open={this.state.showEditForm} onClose={this.showEditForm}>
				<form onSubmit={this.companyInfo}>
					Enter Company Info
					<div className="input__row">
						<label>Company Name</label>
						<input
							type="text"
							placeholder="Company Name"
							onChange={linkState(this, 'name')}
							value={this.state.name}
							required
						/>
					</div>
					<div className="input__row">
						<label>License #</label>
						<input
							type="text"
							placeholder="License Number"
							onChange={linkState(this, 'license')}
							pattern="[A-Za-z.0-9]+" 
							value={this.state.license}
							/>
					</div>
					<div className="input__row">
						<label>Email</label>
						<input
							type="text"
							placeholder="Email"
							onChange={linkState(this, 'email')}
							pattern='^[^\s@]+@[^\s@]+\.[^\s@]+'
							value={this.state.email}
						/>
					</div>
					<div className="input__row">
						<label>Phone</label>
						<input
							type="text"
							value={this.state.phone}
							placeholder="Phone - ex. 213-123-4567"
							onChange={linkState(this, 'phone')}
							pattern="(1-)?[0-9]{3}-[0-9]{3}-[0-9]{4}"
						/>
					</div>
					<div className="input__row">
						<label>Address</label>
						<div className="input__row">
							<input
								type="text"
								placeholder="Address"
								onChange={linkState(this, 'address')}
								value={this.state.address}
							/>
						</div>
						<div className="input__row">
							<input
								type="text"
								placeholder="City"
								value={this.state.city}
								onChange={linkState(this, 'city')}
							/>
						</div>
						<div className="input__row">
							<input
								type="text"
								placeholder="State"
								onChange={linkState(this, 'state')}
								pattern="^[a-zA-Z]{2}"
								value={this.state.state}
							/>
						</div>
						<div className="input__row">
							<input
								type="text"
								placeholder="Zip"
								value={this.state.zip}
								onChange={linkState(this, 'zip')}
								pattern="^[0-9]+"
							/>
						</div>
						<div className="input__row">
						<label>Invoice Download Path</label>
						<input
							type="text"
							placeholder="Invoice Download Path"
							onChange={linkState(this, 'downloadPath')}
							value={this.state.downloadPath}
							/>
					</div>						
					</div>
					<button type="submit" className="btn">
						Submit
					</button>
				</form>
			</Popup>

		</div>
	}
}

MyAccount.defaultProps = {
	company: {}
};

const mapStateToProps = state => {
	return { company: state.company.info, errorMsg: state.errors.errorMsg };
};


const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getCompanyInfo() {
			dispatch(getCompanyInfo(ownProps.auth.user.token))
		},
		editCompanyInfo(company, cb) {
			dispatch(editCompanyInfo(company, ownProps.auth.user.token, cb))
		},
		addCompanyInfo(company, cb) {
			dispatch(addCompanyInfo(company, ownProps.auth.user.token, cb))
		},
		logOutUser(invoiceData) {
			dispatch(logOutUser(invoiceData, ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
