import { combineReducers } from 'redux';
import {
	SIGN_IN_USER_SUCCESS,
	SIGN_IN_USER_ERROR,
	GET_USER_BY_TOKEN_SUCCESS,
	GET_USER_BY_TOKEN_ERROR,
	LOG_OUT_USER,
	GET_LATEST_INVOICES,
	GET_CUSTOMER_INVOICES,
	GET_CUSTOMERS_SUCCESS,
	ADD_CUSTOMER_SUCCESS,
	ADD_CUSTOMER_ERROR,
	EDIT_CUSTOMER_SUCCESS,
	EDIT_CUSTOMER_ERROR,
	DELETE_CUSTOMER_SUCCESS,
	DELETE_CUSTOMER_ERROR,
	CACHE_CUSTOMER_BY_ID,
	GET_CUSTOMER_BY_ID_SUCCESS,
	ADD_INVOICE_SUCCESS,
	ADD_INVOICE_ERROR,
	GET_INVOICE_COUNT,
	CLEAR_OUT_INVOICE_MESSAGE,
	DELETE_INVOICE_BY_ID_SUCCESS,
	DELETE_INVOICE_BY_ID_ERROR,
	GET_INVOICE_BY_ID_SUCCESS,
	GET_INVOICE_BY_ID_ERROR,
	EDIT_INVOICE_SUCCESS,
	EDIT_INVOICE_ERROR,
	CACHE_INVOICE_BY_ID,
	HANDLE_ERROR_MSG,
	RESET_ERROR_MSG,
	GET_COMPANY_INFO_SUCCESS,
	ADD_COMPANY_INFO_SUCCESS,
	EDIT_COMPANY_INFO_SUCCESS,
	GET_USERS_SUCCESS
} from './actions';

// state in this "individual reducer" will refer to THIS state... not the entire state
const authenticate = (
	state = {
		isAuthenticated: false,
		user: {},
		errorMsg: '',
		tokenErrorMsg: ''
	},
	action
) => {
	if (action.type === SIGN_IN_USER_SUCCESS || action.type === GET_USER_BY_TOKEN_SUCCESS) {
		return Object.assign({}, state, {
			user: action.payload,
			isAuthenticated: true
		});
	} else if (action.type === SIGN_IN_USER_ERROR) {
		return Object.assign({}, state, { errorMsg: action.payload });
	} else if (action.type === GET_USER_BY_TOKEN_ERROR) {
		return Object.assign({}, state, { tokenErrorMsg: action.payload });
	}

	return state;
};

const company = (state = { info: {} }, action) => {
	if (action.type === GET_COMPANY_INFO_SUCCESS || action.type === EDIT_COMPANY_INFO_SUCCESS || action.type === ADD_COMPANY_INFO_SUCCESS) {
		return Object.assign({}, state, {
			info: action.payload
		});
	}

	return state;
};

const users = (state = { all: [] }, action) => {
	if (action.type === GET_USERS_SUCCESS) {
		return Object.assign({}, state, {
			all: action.payload
		});
	}

	return state;
};

const invoices = (state = { latest: [], count: 0, addInvoiceErrorMsg: '', addInvoiceSuccessMsg: '', editInvoiceSuccessMsg: '', editInvoiceErrorMsg: '', deleteInvoiceSuccessMsg: '', deleteInvoiceErrorMsg: '', getInvoiceByIdErrorMsg: '', getInvoiceByIdSuccessMsg: '' }, action) => {
	if (action.type === GET_LATEST_INVOICES) {
		return Object.assign({}, state, { latest: state.latest.concat(action.payload) });
	} else if (action.type === ADD_INVOICE_SUCCESS) {
		return Object.assign({}, state, {
			latest: state.latest.concat(action.payload),
			addInvoiceSuccessMsg: `${action.payload._id}`,
			[action.payload._id]: action.payload
		});
	}
	else if (action.type === ADD_INVOICE_ERROR) {
		return Object.assign({}, state, { addInvoiceErrorMsg: action.payload });
	}
	else if (action.type === CLEAR_OUT_INVOICE_MESSAGE) {
		return Object.assign({}, state, { [action.payload]: "" });
	}
	else if (action.type === DELETE_INVOICE_BY_ID_SUCCESS) {
		const deleteInvoiceSuccessMsg = `${action.payload._id}`;
		const latest = state.latest.filter(invoice => invoice._id !== action.payload._id);

		let newState = Object.assign({}, state, { latest, deleteInvoiceSuccessMsg });
		delete newState[action.payload._id];
		return newState;
	}
	else if (action.type === DELETE_INVOICE_BY_ID_ERROR) {
		return Object.assign({}, state, { deleteInvoiceErrorMsg: action.payload });
	}
	else if (action.type === EDIT_INVOICE_SUCCESS) {
		const editInvoiceSuccessMsg = `${action.payload._id}`;
		const latest = state.latest.map((item) => (item._id === action.payload._id) ? action.payload : item);
		return Object.assign({}, state, { [action.payload._id]: action.payload, latest, editInvoiceSuccessMsg });
	}
	else if (action.type === GET_INVOICE_BY_ID_SUCCESS) {
		return Object.assign({}, state, { [action.payload._id]: action.payload });
	}
	else if (action.type === GET_INVOICE_BY_ID_ERROR) {
		return Object.assign({}, state, { getInvoiceByIdErrorMsg: action.payload })
	}
	else if (action.type === EDIT_INVOICE_ERROR) {
		return Object.assign({}, state, { editInvoiceErrorMsg: action.payload });
	}
	else if (action.type === CACHE_INVOICE_BY_ID) {
		return Object.assign({}, state, { [action.payload._id]: action.payload });
	}
	else if (action.type === GET_INVOICE_COUNT) {
		return Object.assign({}, state, { count: action.payload });
	}

	return state;
};

const customers = (
	state = { latest: [], invoices: {}, editCustomerSuccessId: '', addCustomerErrorMsg: '', editCustomerErrorMsg: '', deleteCustomerErrorMsg: '' },
	action
) => {
	if (action.type === GET_CUSTOMERS_SUCCESS) {
		return Object.assign({}, state, { latest: action.payload });
	} else if (action.type === ADD_CUSTOMER_SUCCESS) {
		return Object.assign({}, state, {
			addCustomerErrorMsg: '',
			latest: state.latest.concat([action.payload]),
			[action.payload._id]: action.payload
		});
	} else if (action.type === ADD_CUSTOMER_ERROR) {
		return Object.assign({}, state, {
			addCustomerErrorMsg: action.payload
		});
	} else if (action.type === EDIT_CUSTOMER_SUCCESS) {
		let latest = state.latest.map(customer => {
			if (customer._id === action.payload._id) {
				return action.payload;
			}
			return customer;
		});
		return Object.assign({}, state, { latest, editCustomerErrorMsg: '', [action.payload._id]: action.payload, editCustomerSuccessId: action.payload._id });
	} else if (action.type === EDIT_CUSTOMER_ERROR) {
		return Object.assign({}, state, {
			editCustomerErrorMsg: action.payload
		});
	} else if (action.type === DELETE_CUSTOMER_SUCCESS) {
		let latest = state.latest.filter(customer => {
			return customer._id !== action.payload._id;
		});

		return Object.assign({}, state, { latest, deleteCustomerErrorMsg: '' });
	} else if (action.type === DELETE_CUSTOMER_ERROR) {
		return Object.assign({}, state, {
			deleteCustomerErrorMsg: action.payload
		});
	} else if (action.type === CACHE_CUSTOMER_BY_ID || action.type === GET_CUSTOMER_BY_ID_SUCCESS) {
		return Object.assign({}, state, { [action.payload._id]: action.payload });
	}
	else if (action.type === GET_CUSTOMER_INVOICES) {
		return Object.assign({}, state, { invoices: { [action.payload._id]: action.payload.invoices } });
	}

	return state;
};

const errors = (state = { errorMsg: "" }, action) => {
	if (action.type === HANDLE_ERROR_MSG || action.type === RESET_ERROR_MSG) {
		return { errorMsg: action.payload }
	}
	return state;
}

// The main App Reducer
const appReducer = combineReducers({ auth: authenticate, invoices, customers, errors, company, users });

// The root
const rootReducer = (state, action) => {
	// Reset ALL state back to defaults
	// See: stackoverflow.com/questions/35622588/how
	if (action.type === LOG_OUT_USER) {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
