import { h, Component } from 'preact';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { cacheCustomerById } from '../store/actioncreators';
import format from 'date-fns/format';

class InvoiceRow extends Component {
	constructor(props) {
		super(props);
		this.passCustomer = this.passCustomer.bind(this);
	}
	passCustomer() {
		this.props.cacheCustomer(this.props.customer);
	}
	render({ _id, customer, price, date, total, type }) {
		return (
			<div class="invoice__row">
				<Link style={{ "display": 'block' }} to={`/invoice/${_id}`}>
					<div className="row">
						<div className="col-lg-3">
							{_id}
						</div>
						<div className="col-lg-2">
							{type}
						</div>
						<div className="col-lg-3">
							<Link to={`/customer/${customer._id}`} onClick={this.passCustomer}>
								{customer.name.first} {customer.name.last}
							</Link>
						</div>
						<div className="col-lg-2">
							{format(date, 'MM-DD-YYYY')}
						</div>
						<div className="col-lg-2">
							{total ? '$' : null}{total || 0}
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	cacheCustomer(customer) {
		dispatch(cacheCustomerById(customer));
	}
});

export default connect(null, mapDispatchToProps)(InvoiceRow);
