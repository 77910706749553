import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { editInvoice, getInvoiceById } from '../store/actioncreators';
import InvoiceForm from './InvoiceForm';

class EditInvoice extends Component {
	constructor(props) {
		super(props);
		if (this.props.invoice.items.length === 0) {
			this.props.getInvoiceById(this.props.id);
		}
		this.editInvoiceHandler = this.editInvoiceHandler.bind(this);
	}
	editInvoiceHandler(data) {
		const invoice = Object.assign({ _id: this.props.invoice._id }, data);
		this.props.editInvoice(invoice);
	}
	render() {
		console.log(this.props.invoice);
		return this.props.invoice.items.length === 0 ? "loading " : (
			<InvoiceForm
				title={this.props.invoice.title}
				items={this.props.invoice.items}
				total={this.props.invoice.total}
				customer={this.props.invoice.customer}
				auth={this.props.auth}
				btnTitle="Edit Invoice"
				onSubmit={this.editInvoiceHandler}
				numOfInputs={this.props.invoice.items.length}
				showType={true}
				type={this.props.invoice.type}
				date={this.props.invoice.date}
				_id={this.props.invoice._id}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		invoice: state.invoices[ownProps.id] || { items: [] }
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		getInvoiceById(_id) {
			dispatch(getInvoiceById(_id, ownProps.auth.user.token));
		},
		editInvoice(invoiceData) {
			dispatch(editInvoice(invoiceData, ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
