import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { addCustomer } from '../store/actioncreators';
import CustomerForm from './CustomerForm';

class AddCustomer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customerData : {}
		}
	}
	componentWillReceiveProps(incomingProps){
		if(incomingProps.latest > this.props.latest){
			alert('Successfully added a new customer');
			const latestId = incomingProps.latest[incomingProps.latest.length - 1]._id;
			// TODO Get the new customer ID
			window.location = `/customer/${latestId}`;
		}
	}	
	render() {
		return <div>
			<h1>Add a new customer</h1>
			<CustomerForm customer={this.state.customerData} handleSubmit={this.props.addCustomer} />
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		latest: state.customers.latest
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addCustomer(customerData) {
			dispatch(addCustomer(customerData, ownProps.auth.user.token));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);