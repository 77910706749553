import { h, render, Component } from 'preact';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import FourOhFour from './FourOhFour';
import Authenticated from './Authenticated';
import Dashboard from './Dashboard';
import store from '../store/store';
import SignIn from './SignIn';
import AddNewUser from './AddNewUser';
import Users from './Users';
import AddInvoice from './AddInvoice';
import AddCustomer from './AddCustomer';
import EditInvoice from './EditInvoice';
import Customers from './Customers';
import CustomerDetail from './CustomerDetail';
import InvoiceDetail from './InvoiceDetail';
import MyAccount from './MyAccount';

const App = () =>
	<Provider store={store}>
		<Switch>
			<Route
				exact
				path="/"
				component={() =>
					<Authenticated>
						<Dashboard />
					</Authenticated>
				}
			/>
			<Route exact path="/login" component={SignIn} />
			<Route
				exact
				path="/customers"
				component={() =>
					<Authenticated>
						<Customers isEditable={true} />
					</Authenticated>}
			/>
			<Route
				exact
				path="/add/customer"
				component={() =>
					<Authenticated>
						<AddCustomer />
					</Authenticated>}
			/>
			<Route
				path="/customer/:id"
				component={props =>
					<Authenticated>
						<CustomerDetail id={props.match.params.id} />
					</Authenticated>}
			/>
			<Route
				exact
				path="/add/invoice"
				component={() =>
					<Authenticated>
						<AddInvoice type="invoice" />
					</Authenticated>}
			/>
			<Route
				exact
				path="/add/proposal"
				component={() =>
					<Authenticated>
						<AddInvoice type="proposal" />
					</Authenticated>}
			/>
			<Route
				exact
				path="/edit/invoice/:id"
				component={(props) =>
					<Authenticated>
						<EditInvoice id={props.match.params.id}></EditInvoice>
					</Authenticated>}
			/>
			<Route
				path="/invoice/:id"
				component={props =>
					<Authenticated>
						<InvoiceDetail id={props.match.params.id} />
					</Authenticated>}
			/>
			<Route
				exact
				path="/add/user"
				component={() =>
					<Authenticated>
						<AddNewUser />
					</Authenticated>}
			/>
			<Route
				exact
				path="/users"
				component={() =>
					<Authenticated>
						<Users />
					</Authenticated>}
			/>			
			<Route
				exact
				path="/myaccount"
				component={() =>
					<Authenticated>
						<MyAccount />
					</Authenticated>}
			/>			
			<Route component={FourOhFour} />
		</Switch>
	</Provider>;

export default App;
